import React from 'react';
import styled from 'styled-components';
import Link from 'next/link';

import Logo from '@src/components/icons/logo';

import { ThemeType } from '@src/types/theme';

function HeaderLogo() {
	return (
		<Link href="/" legacyBehavior>
			<A>
				<Logo width="100%" height="100%" />
			</A>
		</Link>
	);
}

export default React.memo(HeaderLogo);

const A = styled.a`
	width: 0.94rem;
	margin: 0.13rem;
	display: flex;

	${({ theme }: { theme: ThemeType }) => theme.breakpoint.labtop`
		width: 0.82rem;
	`}
	cursor: pointer;
	&:hover {
		transition: all 0.3s ease-in-out;
		transform: scale(1.1);
	}
`;
