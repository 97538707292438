import React from 'react';
import styled from 'styled-components';

import cssMixin from '@src/style';
import { P, Color } from '@src/components/atoms';

import LinkWrapper from '@src/components/molecules/link';

const FooterInfoDataLeft = [
	// { label: '고객센터 (채널톡)', text: 'AM 10:30 - PM 06:00 (LUNCH PM 12:00 - PM 01:30)' + "\n" + 'MON -FRI (SAT, SUN, HOLIDAY CLOSED)' },
	{ label: '이메일(고객센터)', text: 'pieceofmood@naver.com' },
	{ label: '인스타그램', text: '@topgimil' },
];

const FooterInfoDataBottom = [
	{ label: '상호명', text: '주식회사 피스오브무드(사업자/법인사업자)' },
	{ label: '대표자', text: '박유진' },
	{ label: '사업자등록번호', text: '735-86-01467' },
	{ label: '통신판매업번호', text: '2019-서울마포-2170' },
	{
		label: '사업장 소재지',
		text: '서울특별시 종로구 종로 6, 5층 스타트업빌리지(서린동, 광화문우체국)',
	},
];

const FooterInfoDataBottom2 = { 
	text: '상호명 | 주식회사 피스오브무드(사업자/법인사업자)   대표자 | 박유진   사업자등록번호 | 735-86-01467   통신판매업번호 | 2019-서울마포-2170   사업장 소재지 | 서울특별시 종로구 종로 6, 5층 스타트업빌리지(서린동, 광화문우체국)' 
};

const FooterInfoDataRight = [
	{ text: '회사소개', link: 'https://www.notion.so/cbce327f93c245c093f3d54c154c9d66' },
	{ text: '이용약관', link: 'https://www.notion.so/d47c416cf43c4863bd17334094ab29a7' },
	{ text: '개인정보처리방침', link: 'https://www.notion.so/b98e9274d14f4e9783bd8fb1db9a467d' },
	{ text: '교환/환불 정책', link: 'https://www.notion.so/f80700d7d0a04c648a5400cacc877f80' },
];

export default function FooterInfo() {
	return (
		<Wrapper>
			<P level={9} fontWeight="bold" color='#838383' mb={14} hideBreakPoint="mobile" style={{marginBottom:'0.315rem', fontFamily:'HG'}}>
				TOPGIMIL
			</P>
			<Row3>
				<div>
					{FooterInfoDataLeft.map(data => (
						<DataWrapper>
							<MobileP2 color="#838383" fontSizes={[14, 12]} mr={12} pre>{data.label}</MobileP2>
							<MobileP color="#838383" fontSizes={[14, 12]} style={{lineHeight: '1.4'}}>{data.text}</MobileP>
						</DataWrapper>
					))}
				</div>
				<Row2 style={{height:'100%'}}>
					{FooterInfoDataRight.map(data => (
						<LinkWrapper href={data.link}>
							<MobileP3 color="#838383" fontSizes={[14, 12]} style={{lineHeight: '1.4', fontWeight: 'bold'}}>{data.text}</MobileP3>
						</LinkWrapper>
					))}
				</Row2>
			</Row3>

			<Line style={{border: '0.8px solid #dadada', marginTop:'0.24rem', marginBottom:'0.28rem'}}></Line>

			<DataWrapper2>
				{FooterInfoDataBottom.map(data => (
					<Row style={{marginTop: '0.08rem'}}>
						<P color="#9b9b9b" fontSizes={[14, 12]} mr={12} pre style={{marginRight: '0.12rem'}}>{data.label}</P>
						<P color="#9b9b9b" fontSizes={[14, 12]} style={{lineHeight: '1.4', marginRight: '0.32rem'}}>{data.text}</P>
					</Row>
				))}
			</DataWrapper2>

			<MobileOnlyP>{FooterInfoDataBottom2.text}</MobileOnlyP>

		</Wrapper>
	);
}

const Wrapper = styled.div`
	${cssMixin.flexCol};
	${({ theme }) => theme.breakpoint.tablet`
		margin-right: 0;
	`}
	width: 100%;
	font-family: 'Spoqa Han Sans Neo';
`;

const DataWrapper = styled.div`
	${cssMixin.flexRow};
	margin-bottom: 0.08rem;
	align-items: flex-start;
	white-space: break-spaces;
	display: flex;
	flex-wrap: wrap;
`;

const DataWrapper2 = styled.div`
	${cssMixin.flexRow};
	margin-bottom: 0.08rem;
	align-items: flex-start;
	white-space: break-spaces;
	display: flex;
	flex-wrap: wrap;
	${({ theme }) => theme.breakpoint.mobile`
		display: none;
	`}
`;


const Row = styled.div`
	${cssMixin.flexRow};

	${({ theme }) => theme.breakpoint.mobile`
		${cssMixin.flexCol};
	`}
`;

const Row2 = styled.div`
	margin-left: auto;

	${cssMixin.flexRow};

	${({ theme }) => theme.breakpoint.mobile`
		margin-left: 0;
		justify-content: space-between;
		margin-top: 0.24rem;
		margin-bottom: 0.16rem;
		height: 100%;
	`}
`;

const Row3 = styled.div`
	${cssMixin.flexRow};
	align-items: flex-start;

	${({ theme }) => theme.breakpoint.mobile`
		${cssMixin.flexCol};
		align-items: normal;
	`}
`;

const MobileP = styled(P)`
	${({ theme }) => theme.breakpoint.mobile`
		margin-bottom: 0.04rem;
	`}
`

const MobileP2 = styled(P)`
 	width: 1.2rem;
	${({ theme }) => theme.breakpoint.mobile`
		margin-bottom: 0.04rem;
		width: auto;
	`}
`

const MobileP3 = styled(P)`
 	margin-left: 0.56rem;
	${({ theme }) => theme.breakpoint.mobile`
		margin-left: 0rem;
	`}
`

const Line = styled.div`
	${({ theme }) => theme.breakpoint.mobile`
		display: none;
	`}
`;

const MobileOnlyP = styled(P)`
 	display: none;
	color: #9b9b9b;
	font-size: 0.12rem;
	letter-spacing: -0.2px;
	line-height: 1.4;
	${({ theme }) => theme.breakpoint.mobile`
		display: flex;
	`}
`