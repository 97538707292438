import React from 'react';

import { IconProps } from '@src/types';
import { Color } from '../atoms';

export default function Logo(props: IconProps) {
	const { fill = Color.BLACK } = props;

	return (
		<svg viewBox="0 0 71 16" fill="none" {...props}>
			<path
				d="M8.906 2.885h-3.02v12.217H3.357V2.885H.508V.567h8.398v2.318zM16.337 10.922V4.671c0-1.349-.703-1.976-2.033-1.976-1.35 0-2.033.627-2.033 1.976v6.251c0 1.33.684 1.957 2.033 1.957 1.33 0 2.033-.627 2.033-1.957zm2.527-6.441v6.612c0 2.584-1.71 4.085-4.56 4.085-2.87 0-4.56-1.501-4.56-4.085V4.481c0-2.584 1.69-4.104 4.56-4.104 2.85 0 4.56 1.52 4.56 4.104zM29.194 5.26c0 2.983-1.596 4.674-4.636 4.674h-1.596v5.073h-2.508V.567h4.104c3.04 0 4.636 1.691 4.636 4.693zm-2.508 0c0-1.748-.855-2.375-2.318-2.375h-1.406v4.731h1.406c1.463 0 2.318-.627 2.318-2.356zM30.228 11.188V4.481c0-2.584 1.52-4.104 4.56-4.104 2.983 0 4.332 1.482 4.39 4.009h-2.509c-.057-1.159-.684-1.691-1.88-1.691-1.35 0-2.034.646-2.034 1.976v6.327c0 1.273.703 1.881 2.033 1.881s2.033-.57 2.033-1.881V9.041h-2.128V6.723h4.655v4.465c0 2.565-1.615 3.99-4.56 3.99-3.04 0-4.56-1.425-4.56-3.99zM43.618 15.007H41.11V.567h2.508v14.44zM48.18.567l2.679 7.581L53.538.567h2.584v14.44h-2.508v-5.7l.133-3.591-2.033 6.631h-1.805l-1.957-6.612.152 3.572v5.7h-2.508V.567h2.584zM60.595 15.007h-2.508V.567h2.508v14.44zM70.42 15.007h-7.847V.567h2.508v12.141h5.34v2.299z"
				fill={fill}
			/>
		</svg>
	);
}
