import React from 'react';
import styled from 'styled-components';

import HeaderLogo from '../logo';
import Navigation from './navigation';
import cssMixin from '@src/style';

export default function DesktopHeader() {
	return (
		<Wrapper>
			<HeaderLogo />
			<Navigation />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	${cssMixin.container}
	${cssMixin.flexRow}
	justify-content:space-between;
	height: 0.65rem;

	${props => props.theme.breakpoint.labtop`
		display:none;
	`}
`;
