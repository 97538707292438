import React from 'react';
import styled from 'styled-components';

import Header from '@src/components/common/header';
import Footer from '@src/components/common/footer';
import NavigationBar from '@src/components/common/navigation-bar';
import MetaTags, { MetaTagsProps } from '@src/lib/meta';
import { withDeviceSize } from '@src/context/DeviceSize';
import { P } from '@src/components/atoms';
import Modal from '@material-ui/core/Modal';

export type MainLayoutProps = {
	children: any;
	disableHeaderScroll?: boolean;
	hasBottomButton?: boolean;
	isBackHeader?: boolean;
	withoutNavigationBar?: boolean;
} & MetaTagsProps;

function MainLayout({
	children,
	disableHeaderScroll,
	hasBottomButton,
	isBackHeader,
	withoutNavigationBar,
	title,
	siteTitle,
	siteDescription,
	pageUrl,
	ogImage,
}: MainLayoutProps) {
	return (
		<>
			<MetaTags {...{ title, siteTitle, siteDescription, pageUrl, ogImage }} />
			<Header disableScroll={disableHeaderScroll} isBack={isBackHeader} />
			{/* {!withoutNavigationBar && <NavigationBar />} */}
			{children}
			<Footer />
			{hasBottomButton && <Space />}
			{/* <Modal
				open={true}
				onClose={() => {}}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
				style={{
					width: '100%',
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<div
					style={{
						display: 'flex',
						width: '80%',
						height: '80%',
						background: 'white',
						alignSelf: 'center',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<P
						level={5}
						fontWeight="bold"
						preWrap
						textAlign="center"
						lineHeight={1.5}
					>{`사이트 업데이트 중입니다. 잠시 후 이용해주세요.\n(17:00~18:00)`}</P>
				</div>
			</Modal> */}
		</>
	);
}

export default MainLayout;

const Space = styled.div`
	display: none;
	width: 1px;
	height: 0.68rem;
	${({ theme }) => theme.breakpoint.labtop`
		display:flex;
	`}
`;
