import React from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

const MobileHeader = dynamic(() => import('./mobile'));
import DesktopHeader from './desktop';

export type HeaderProps = { disableScroll?: boolean; isBack?: boolean };

function Header({ disableScroll, isBack }: HeaderProps) {
	return (
		<Wrapper>
			<DesktopHeader />
			<MobileHeader {...{ disableScroll, isBack }} />
		</Wrapper>
	);
}

export default React.memo(Header);

const Wrapper = styled.header`
	width: 100%;
	border-bottom: 1px solid #a8aeb3;
`;
