import React, { ReactChild } from 'react';
import Link from 'next/link';

type AProps = {
	href: string;
	as?: string;
	children: ReactChild;
	style?: React.CSSProperties;
	onClick?: () => void;
	className?: string;
};

export default function LinkWrapper({ href, as, children, style, onClick, className }: AProps) {
	return (
        (<Link href={href} as={as} style={style} onClick={onClick} className={className}>

            {children}

        </Link>)
    );
}
