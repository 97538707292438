import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import cssMixin from '@src/style';
import { P, Img } from '@src/components/atoms';
import LinkWrapper from '@src/components/molecules/link';

export default function NavigationItems() {
	const router = useRouter();

	return (
		<Wrapper>
			<LinkWrapper href="/">
				<Home>
					<P
						level={1}
						style={{
							fontWeight: router.pathname == '/' ? 800 : 600,
							color: router.pathname == '/' ? '#1c1c1c' : '#838383',
						}}
					>
						홈
					</P>
				</Home>
			</LinkWrapper>
			{/* <LinkWrapper href="/events">
				<MyPage>
					<P
						level={1}
						style={{
							fontWeight: router.pathname == '/events' ? 800 : 600,
							color: router.pathname == '/events' ? '#1c1c1c' : '#838383',
						}}
					>
						지금 할인
					</P>
				</MyPage>
			</LinkWrapper> */}
			<LinkWrapper href="/category">
				<Category>
					<P
						level={1}
						style={{
							fontWeight: router.pathname == '/category' ? 800 : 600,
							color: router.pathname == '/category' ? '#1c1c1c' : '#838383',
						}}
					>
						카테고리
					</P>
				</Category>
			</LinkWrapper>
			<LinkWrapper href="https://topgimilassistants.oopy.io/">
				<BetaService>
					<P
						level={1}
						style={{
							fontWeight: router.pathname == '/betaService' ? 800 : 600,
							color: router.pathname == '/betaService' ? '#1c1c1c' : '#838383',
						}}
					>
						베타서비스
					</P>
					<P
						pxSize={12}
						style={{
							fontWeight: 600,
							color: '#FFBB0D',
							marginBottom: '5px',
							marginLeft: '2px',
						}}
					>
						NEW
					</P>
				</BetaService>
			</LinkWrapper>
			<LinkWrapper href="/cart">
				<Cart>
					<P
						level={1}
						style={{
							fontWeight: router.pathname == '/cart' ? 800 : 600,
							color: router.pathname == '/cart' ? '#1c1c1c' : '#838383',
						}}
					>
						장바구니
					</P>
				</Cart>
			</LinkWrapper>
			<LinkWrapper href="/mypage">
				<MyPage>
					<P
						level={1}
						style={{
							fontWeight: router.pathname == '/mypage' ? 800 : 600,
							color: router.pathname == '/mypage' ? '#1c1c1c' : '#838383',
						}}
					>
						내 서재
					</P>
				</MyPage>
			</LinkWrapper>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	${cssMixin.flexRow}
`;

const Home = styled.div`
	${cssMixin.center}
	margin-right: 0.8rem;
`;

const Category = styled.div`
	${cssMixin.center}
	margin-right: 0.8rem;
`;

const Cart = styled.div`
	${cssMixin.center}
	margin-right: 0.8rem;
`;

const MyPage = styled.div`
	${cssMixin.center}
	margin-right: 0.8rem;
`;

const BetaService = styled.div`
	${cssMixin.center}
	margin-right: 0.6rem;
`;
