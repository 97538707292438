import React, { useState } from 'react';
import styled from 'styled-components';

import cssMixin from '@src/style';
import FooterInfo from './info';
import { Color, P } from '@src/components/atoms';
import ChevronDownIcon from '@src/components/icons/chevron/down';
import buttonStyle from '@src/style/button';
import ChevronUpIcon from '@src/components/icons/chevron/up';

export default function Footer() {
	return (
		<Background>
			<MobileHeader>
				<P level={5} fontWeight="bold" color='#838383'>
					TOPGIMIL
				</P>
			</MobileHeader>
			<Wrapper>
				<FooterInfo />
			</Wrapper>
		</Background>
	);
}
const Background = styled.div`
	${cssMixin.flexCol};
	width: 100%;
	border-top: 1px solid ${Color.GREY};
	background-color: #f7f7f7;
	padding-bottom: 0.32rem;
`;

const Wrapper = styled.div`
	${cssMixin.container};
	${cssMixin.flexRow};
	align-items: flex-start;

	padding: 0.36rem 0 0.58rem;
	${({ theme }) => theme.labtop`
		justify-content:space-between;
	`}
	${({ theme }) => theme.tablet`
		${cssMixin.flexCol}
		padding: 0.4rem 0 0.48rem;
	`}
	${({ theme }) => theme.breakpoint.mobile`
		padding-top:0.1rem;	
		margin-left: 0.20rem;
		margin-right: 0.20rem;
	`}
`;

const IconWrapper = styled.button`
	display: none;
	${({ theme }) => theme.breakpoint.mobile`
		${buttonStyle.Default};
		height:	0.3rem;	
	`}
`;

const MobileHeader = styled.div`
	display: none;

	${({ theme }) => theme.breakpoint.mobile`
		${cssMixin.flexRow};
		width: 100%;
		height:	0.3rem;
		margin:0.32rem 0rem 0.16rem 0rem;
		padding-left: 0.20rem;
		padding-right: 0.20rem;
		justify-content: space-between;
	`}
`;
