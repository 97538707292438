import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';

const UnAuthNavigationContainer = dynamic(() => import('./unauth-navigation'));
const Profile = dynamic(() => import('./profile'));

import { getCookie } from '@src/lib/util/cookie';
import NavigationItems from './items';
import cssMixin from '@src/style';

function Navigation() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	useEffect(() => {
		if (process.browser) {
			setIsLoggedIn(!!getCookie('accessToken'));
		}
	}, []);

	return (
		<Wrapper>
			<NavigationItems />
			{!isLoggedIn && <UnAuthNavigationContainer />}
			{isLoggedIn && <Profile />}
		</Wrapper>
	);
}
export default React.memo(Navigation);

const Wrapper = styled.div`
	${cssMixin.flexRow};
`;
