import React from 'react';
import Head from 'next/head';

export type MetaTagsProps = {
	title?: string;
	siteTitle?: string;
	siteDescription?: string;
	ogImage?: string;
	pageUrl?: string;
};

function MetaTags({ siteTitle, title, ogImage, siteDescription, pageUrl }: MetaTagsProps) {
	const viewSiteTitle = siteTitle ? ' | ' + siteTitle : '';
	const pageTitle = title ? title : '탑기밀' + viewSiteTitle;
	const ogImgUrl = ogImage ? ogImage : '/images/logo.png';
	const ogTitle = title ? title : '탑기밀' + viewSiteTitle;

	return (
		<Head>
			<title>{pageTitle}</title>
			<meta property="og:title" content={ogTitle} />
			<meta property="og:image" content={ogImgUrl} />
			<meta property="og:image:width" content="400" />
			<meta property="og:image:height" content="300" />
			{siteDescription && (
				<>
					<meta name="description" content={siteDescription} />
					<meta property="og:description" content={siteDescription} />
				</>
			)}
			{pageUrl && (
				<>
					<meta property="og:url" content={pageUrl} />
					<link rel="canonical" href={pageUrl} />
				</>
			)}
		</Head>
	);
}

export default React.memo(MetaTags);
